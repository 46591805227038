import React from "react"

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            x="0"
            y="0"
            fill="#F3F3F3"
            viewBox="0 0 20 20"
        >
            <path d="M7 2L7 0 0 0 0 7 2 7 2 3.4 5.6 7 7 5.6 3.4 2z"></path>
            <path d="M5.6 13L2 16.6 2 13 0 13 0 20 7 20 7 18 3.4 18 7 14.4z"></path>
            <path d="M18 16.6L14.4 13 13 14.4 16.6 18 13 18 13 20 20 20 20 13 18 13z"></path>
            <path d="M13 0L13 2 16.6 2 13 5.6 14.4 7 18 3.4 18 7 20 7 20 0z"></path>
        </svg>
    )
}

export default Icon
