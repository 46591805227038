import React from "react"
import { Button } from "react-bootstrap"
import { Link } from "gatsby"
import { PageCenter } from "../../../components/elements/wrappers"
import { DefaultHeader } from "../../../components/elements"

import ExpandIcon from "../../../components/elements/research-catalogue/ExpandIcon.jsx"
import { Page } from "../../../components/elements"

export default function ResearchCataloguePage() {
    return (
        <Page
            title="Research Catalogue"
            description="Abstracts, Table of Contents, List of Figures, and/or Scope-of-work"
        >
            <PageCenter bg="white">
                <DefaultHeader
                    title="Research Catalogue"
                    subtitle="EGI Reports (represented by a red pin on the map) are available for download to each Corporate Associate Member via EGIconnect. For an overview of any or all of the 850+ completed reports, zoom in to the map view and click any red pin to access the project summary. For Current Projects that are In Development, In Progress or are Completed and available for immediate delivery to sponsors, you may access research details, contacts and how-to-sponsor information on the Current Projects page."
                    type="small"
                />
                <div className="text-center">
                    <Link to="/research/project-portfolio">
                        <Button variant="egi" className="mb-3">
                            View EGI Project Reports
                        </Button>
                    </Link>
                    <Link to="/research/current-projects">
                        <Button variant="egi" className="mb-3 mx-3">
                            View Current Projects
                        </Button>
                    </Link>
                </div>
                <a
                    href="https://egigis.maps.arcgis.com/apps/Minimalist/index.html?appid=402e138f9bfa4bf88165c43978fdbfb2&extent=-151.875,-45.0625,96.6797,64.3372&home=true&zoom=true&previewImage=false&scale=true&disable_scroll=true&theme=light"
                    target="new"
                    style={{
                        float: "right",
                        filter: "invert(.1)",
                        transform: "translate(-5px, 45px)",
                    }}
                >
                    <ExpandIcon />
                </a>

                <iframe
                    width="100%"
                    height="650"
                    frameBorder="0"
                    scrolling="no"
                    marginHeight="0"
                    marginWidth="0"
                    title="EGI Research Catalogue Web Map"
                    src="https://egigis.maps.arcgis.com/apps/Minimalist/index.html?appid=402e138f9bfa4bf88165c43978fdbfb2&extent=-151.875,-45.0625,96.6797,64.3372&home=true&zoom=true&previewImage=false&scale=true&disable_scroll=true&theme=light"
                >
                    Sorry, it looks like your browser doesn&apos;t support
                    iFrame. Try this link to view the map:
                    <a href="https://egigis.maps.arcgis.com/apps/Minimalist/index.html?appid=402e138f9bfa4bf88165c43978fdbfb2&extent=-151.875,-45.0625,96.6797,64.3372&home=true&zoom=true&previewImage=false&scale=true&disable_scroll=true&theme=light">
                        Project Map
                    </a>
                </iframe>
            </PageCenter>
        </Page>
    )
}
